import "./Contact.css"
import { FaGithub } from "react-icons/fa";
import { FaLinkedin} from "react-icons/fa";
import { FaTwitterSquare} from "react-icons/fa";
import { useState } from 'react';

import picture from "../images/scale4.png"
import {Link} from "react-router-dom"


const Contact=()=>{

    const [flipped, setFlipped] = useState(false);


    return <div className="contactWrapper">

<div className="flipping-card-container">
      <div
        className={`flipping-card ${flipped ? "flipped" : ""}`}
        onClick={() => setFlipped(!flipped)}
      >
        <div className="flipping-card-front">
          <h2>Moje vizitka</h2>
          <div className="flipping-card-detail">Jan Kopčák</div>
        </div>
        <div className="flipping-card-back">
          <h2>Honza Kopčák</h2>
          <img src={picture} alt="" className="pp" />
          <div className="contact-icons">
          
          <Link className="contact-link"  to="https://github.com/search?q=%40Fidebeda"><FaGithub className="contact-icon"></FaGithub> </Link>

          <Link className="contact-link"  to="https://twitter.com/JanKopcak"><FaTwitterSquare className="contact-icon"></FaTwitterSquare> </Link>

          <Link className="contact-link"  to="https://cz.linkedin.com/in/jan-kop%C4%8D%C3%A1k-28949825a?trk=people-guest_people_search-card"><FaLinkedin className="contact-icon"></FaLinkedin> </Link>

          
          

          </div>
          
          <div className="flipping-card-detail"><p>Můžete mě kontaktovat na <strong><a href="mailto:">fidebeda@seznam.cz</a></strong> </p></div>
        </div>
      </div>
    </div>

    <br />
    <Link className="contact-link"  to="/">Domů</Link>.

    
    


        {/* <div className={styles.myCard}></div> */}
        





    </div>
}

export default Contact