import {NavLink} from "react-router-dom"
import "./NavBar.css"

import { RiMenu4Line } from "react-icons/ri";
import links from "./data"
import {useState} from 'react'
import logo from "../images/7423888_react_react native_icon.png"


const NavBar=()=>{

    const [showMenu,setShowMenu] = useState(false)

  const handlerMenu = ()=>{
    setShowMenu(!showMenu)
  }


  return (
    <nav>
      <div className="navigation">
        <div className="nav-header">
          <img src={logo} alt="" />
          <button onClick={handlerMenu}>
            <RiMenu4Line className='ham-icon'></RiMenu4Line>
          </button>
        </div>
        <div className={`${showMenu ? "nav-list show" : "nav-list hide"}`}>
          <ul>
            {
              links.map((oneLink)=>{
                const {id,url,text} = oneLink
                return <NavLink to={url} className="my-links" key={id}> <a href={url}>{text}</a> </NavLink>
              })
            }
          </ul>

        </div>
      </div>
    </nav>
    
  )







//     return <div>
//         <nav >


            

//             <NavLink className="my-link" to="/">Domů</NavLink>   
//             <NavLink className="my-link" to="/Projects">Projekty</NavLink>
//             <NavLink className="my-link" to="/Contact">Kontakt</NavLink>
            

//   </nav>
//     </div>
}

export default NavBar