const links = [
    {
        id: 1,
        url: "/",
        text: "Domů",
    },
    {
        id: 2,
        url: "/projects",
        text: "Projekty",
    },
    {
        id: 3,
        url: "/contact",
        text: "Kontakt",
    },
    
]


export default links