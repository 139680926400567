
import './App.css';
import NavBar from './components/NavBar';
import FooterBar from './components/FooterBar';
import Home from './components/Home';
import Projects from './components/Projects';
import Contact from './components/Contact';

import {Route,Routes} from "react-router-dom"

function App() {
  
  return (
    <div className="App">
      <header className='head-index'>
        
        <NavBar></NavBar>
        

      </header>


      <main className='content'>

          <Routes>

            <Route  path='/' element={< Home />}></Route>
            <Route  path='/Projects' element={< Projects />}></Route>
            <Route  path='/Contact' element={< Contact />}></Route>
            
          </Routes>
          


</main>
      
      <FooterBar></FooterBar>
    </div>
  );
}

export default App;
