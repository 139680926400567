import styles from './Projects.module.css';
import html from "../images/317755_badge_html_html5_achievement_award_icon.png"
import jquery from "../images/308442_front-end_javascript_jquery_js_library_icon.png"
import javascript from "../images/652581_code_command_develop_javascript_language_icon (2).png"
import react from "../images/7423888_react_react native_icon.png"
import php from "../images/4375039_logo_php_icon.png"
import sass from "../images/4375066_logo_sass_icon.png"
import vue from "../images/9035136_logo_vue_icon (2).png"
import csharp from "../images/csharp_original_logo_icon_146578.png"
import {Link} from "react-router-dom"


const Projects=()=>{
    return <div className={styles.projectsWrapper}>


    <div className={styles.myTab}>

    <table id="dovednosti">
                        
                        <tr class="centrovany">
                            <td>
                                <img src={html} alt="HTML" />
                                <img src={jquery} alt="JQUERY" />
                                <img src={javascript} alt="JAVASCRIPT" />
                                <img src={react} alt="React" />
                                <img src={sass} alt="React" />
                                
                            </td>
                            
                        
                            
                        </tr>
                        <tr>
                            <td>
                                <h2><a href="https://prahapankrac-songs.netlify.app/" target="_blank">React app. iTunes API.</a></h2>
                                <p>Songs</p>
                            </td>
                            
                        </tr> 
    </table>

    </div>


    <div className={styles.myTab}>

    <table id="dovednosti">
                        
                        <tr class="centrovany">
                            <td>
                                <img src={html} alt="HTML" />
                                <img src={jquery} alt="JQUERY" />
                                <img src={javascript} alt="JAVASCRIPT" />
                                <img src={react} alt="React" />
                                
                            </td>
                            
                        
                            
                        </tr>
                        <tr>
                            <td>
                            <h2><a href="https://prahapankrac-currencies.netlify.app/" target="_blank">React app. Currencies APIs.</a></h2>
                            <p>Currencies</p>
                            </td>
                            
                        </tr> 
    </table>


    </div>
    

   <div className={styles.myTab}>

   <table id="dovednosti">
                        
                        <tr class="centrovany">
                            <td>
                                <img src={html} alt="HTML" />
                                <img src={jquery} alt="JQUERY" />
                                <img src={javascript} alt="JAVASCRIPT" />
                                <img src={react} alt="React" />
                                <img src={php} alt="React" />
                                <img src={vue} alt="React" />
                                
                            </td>
                            
                        
                            
                        </tr>
                        <tr>
                            <td>
                            <h2><a href="https://prahapankrac.cz/" target="_blank">prahapankrac.cz</a></h2>
                            <p>Jen tak pro zábavu.</p>
                            </td>
                            
                        </tr> 
    </table>


   </div>

   

   <div className={styles.myTab}>

   <table id="dovednosti">
                        
                        <tr class="centrovany">
                            <td>
                                <img src={html} alt="HTML" />
                                <img src={jquery} alt="JQUERY" />
                                <img src={javascript} alt="JAVASCRIPT" />
                                
                                
                            </td>
                            
                        
                            
                        </tr>
                        <tr>
                            <td>
                            <h2><a href="https://prahapankrac.cz/buttons/odkazy/diceGame/index.html" target="_blank">Dice game</a></h2>
                            <p>Dice game. Pozor aby Vám nepadla jednička. Získejte 30 bodů.</p>
                            </td>
                            
                        </tr> 
    </table>


   </div>


   <div className={styles.myTab}>

    <table id="dovednosti">
                        
                        <tr class="centrovany">
                            <td>
                                <img src={html} alt="HTML" />
                                <img src={jquery} alt="JQUERY" />
                                <img src={javascript} alt="JAVASCRIPT" />
                                <img src={react} alt="React" />
                                
                            </td>
                            
                        
                            
                        </tr>
                        <tr>
                            <td>
                            <h2><a href="https://catsanddogs-hk.netlify.app/" target="_blank">React app. Cats and dogs APIs.</a></h2>
                            <p>Cats and dogs.</p>
                            </td>
                            
                        </tr> 
    </table>


    </div>

    <div className={styles.myTab}>

    <table id="dovednosti">
                        
                        <tr class="centrovany">
                            <td>
                                <img src={csharp} alt="HTML" />
                                
                                
                            </td>
                            
                        
                            
                        </tr>
                        <tr>
                            <td>
                            <h2><a href="https://github.com/Fidebeda/Projekt-EvidencePojistencu" target="_blank">Evidence pojištěnců</a></h2>
                            <p>Konzolová aplikace v C#.</p>
                            </td>
                            
                        </tr> 
    </table>


    </div>

    <Link className="contact-link"  to="/">Domů</Link>

    
        





    </div>
}

export default Projects