import "./Home.css"
import {Link} from "react-router-dom"
import picture from "../images/scale4.png"


const Home=()=>{
    return <div>

                
        <div class="parent">

        <div class="div1">
            
                <div class="info-home">

                    <h1>O mně</h1>
                    <p>Vítejte na mém webu. Učím se neustále nové a nové věci. Někdy mi to jde. Někdy méně.

                        Jmenuji se Honza&nbsp;K. Kontaktovat mě můžete na <Link className="contact-link"  to="/Contact">kontaktní stránce</Link>.
                        
                        Rád tvořím weby a také rád chodím do kina a restaurací.
                        
                        Také rád vařím.
                        
                         </p>
                         <span id="my-projects"><Link className="projects-link"  to="/Projects">MOJE PROJEKTY</Link> </span>
                </div>
            
            
            
            
         </div>

        <div class="div2"> 

        <h2>Trocha té moudrosti ...</h2>
        <div>
        <p>„Věřím, že fantazie je silnější než vědění. Že mýty mají větší moc než historie. Že sny jsou mocnější než skutečnost. Že naděje vždy zvítězí nad zkušeností. Že smích je jediným lékem na zármutek. A věřím, že láska je silnější než smrt.“ ...  „Kecy.“ </p>

        </div>
                


        </div>

        <div class="div3">

        <img className="picture" src={picture} alt="Programátor Honza" />

             </div>
        </div>
            </div>
}

export default Home